import React, { useState, useContext, useEffect, lazy } from "react";

import { faAngleDoubleDown, faCheck, faCogs, faFilter, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import filterArrConfig from "./filterArrConfg.json"
import { apiCall, boolImgRetailer, checkExtraFilterConfig, getCustomLabel, getReviewCountMaxSuggestions, getReviewCountMinSuggestions, isThisFeatureAllowed } from "../common.js";
import TopMenuBar from "../../../contextApi/TopMenuBar";
import { generateFilters, generatePricingFilters } from "../../../utils/routeBasedOperations";
import FilterRange from "../Header/FilterRange";
import FilterNumber from "../Header/FilterNumber";
import FilterBoolean from "../Header/FilterBoolean";
import FilterMinMax from "../Header/FilterMinMax";
import FilterSelect from "../Header/FilterSelect.js";
import SearchBar from "../SearchBar/SearchBar.js";
import SideMenuCommonFilterLib from "../../../contextApi/SideMenuCommonFilterLib.js";
import { contextApiDefaultValueOnClear } from "../Layout/Layout.js";
import UserPersonalizedFilterContext from "../../../contextApi/UserPersonalizedFilterContext.js";
import { SAVED_FILTER, filtersApi } from "../constants.js";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import SideMenuCommonFilter from "../../../contextApi/SideMenuCommonFilter.js";
import { getLocalStorageValue, setLocalStorageValue } from "../../../utils/localStorageOperations.js";
import SavePersonalizedFilter from "../UserPersonalizedFilters/SavePersonalizedFilterModal.js";
import SideMenuCommonFilterDefault from "../../../contextApi/SideMenuCommonFilterDefault.js";
import SavedFilterSelect from "../Header/SavedFilterSelect.js";
import Skeleton from "react-loading-skeleton";
import FilterLocationWidget from "../Header/FilterLocationWidget.js";

const DatePicker = lazy(() => import("../DatePicker"));

const Filters1 = (props) => {
    const {
        applyFilter,
        calender,
        hideSearchBar,
        filterConfig,
        filterBtnsSelected,
        setFilterBtnsSelected,
        updateData,
        searchEventHandler,
        refreshConfig,
        generateFilter=(localStorage.currentModule == "assortment") ? generateFilters : generatePricingFilters,
        miniSubmitButtons,
        hideAdvanceButton,
        headerLoading = false,
        showSavedFilters=true,
        calenderHandler: [calenderDate, setCalenderDate, calenderOptions] = [],
    } = props;

    const TENANT = getLocalStorageValue("tenant");
    const [openFilterLayout, setOpenFilterLayout] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [filterArrayConfig ,setFilterArrayConfig] = useState(filterArrConfig);
    const [clrearAllEvent, setClearAllEvent] = useState(0);
    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);
    const [UserPersonalizedFilterValue, setUserPersonalizedFilterValue] = useContext(UserPersonalizedFilterContext);
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const defaultSideMenuCommonFilterDefaultContext = Object.assign({}, useContext(SideMenuCommonFilterDefault)[0]);
    const [fixTopMenuToOpen, setFixTopMenuToOpen] = useContext(TopMenuBar);
    const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
    const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
    const handleClose = () => setShowSaveFilterModal(false);
    const handleShow = () => setShowSaveFilterModal(true);
    const [isUpdateFilter, setIsUpdateFilter] = useState(false);
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);

    let assortmentSourceRetailersRochebros = {
        "1": "Competitor1",
        "2": "Competitor2",
        "3": "Competitor3",
        "4": "Competitor4"
    }
    
    let imageRetailersRochebrosAssortment = {
      "1": "Competitor1",
      "2": "Competitor2",
      "3": "Competitor3",
      "4": "Competitor4"
    }
    
    let pricingRetailersRochebros = {
      "5": "Stop & Shop"
    }
    
    let imageRetailersRochebrosPricing = {
      "5": "Stop & Shop"
    }
    
    let assortmentSourceRetailersBJS = {
      "1": "Competitor1",
      "2": "Competitor2",
      "3": "Competitor3",
      "4": "Competitor4"
    }
    
    let pricingRetailersBJS = {
      "6": "Amazon",
      "7": "Bestbuy",
      "8": "Costco",
      "9": "Homedepot",
      "10": "Lowes",
      "11": "Samsclub",
      "12": "Target",
      "13": "Walmart"
    }

    useEffect(() => {
        if (Object.keys(filterBtnsSelected).length == 0) {
            let newFilterBtnsSelected = {...filterBtnsSelected};
            filterConfig.map(filterConfigSingle => {
                newFilterBtnsSelected[filterConfigSingle] = new Set(sideMenuCommonFilterContext[filterConfigSingle]);
            });
            setFilterBtnsSelected(newFilterBtnsSelected);
        }
    }, [filterConfig]);

    useEffect(() => {
        let filterArrayConfigNew = {...filterArrayConfig};
        Object.keys(filterArrayConfigNew).map(filterIndex => {
            if (filterIndex == "tenant_priority" && sideMenuCommonFilterLibContext.priorities) {
                filterArrayConfigNew[filterIndex]['list'] = [{
                    tenant_priority: "ALL"
                }].concat(sideMenuCommonFilterLibContext.priorities.map(item => {
                    return {tenant_priority: item};
                }));
            }
            if (filterIndex == "channel" && sideMenuCommonFilterLibContext.channel) {
                filterArrayConfigNew[filterIndex]['list'] = [{
                  channel: "ALL",
                  label: "ALL",
                }].concat(Object.keys(sideMenuCommonFilterLibContext.channel).map(item => {
                    return {
                      channel: sideMenuCommonFilterLibContext.channel[item],
                      label: item
                    };
                }));
            }
            if (filterIndex == "dropship" && sideMenuCommonFilterLibContext.dropship) {
                filterArrayConfigNew[filterIndex]['list'] = [{
                  dropship: "ALL",
                  label: "ALL",
                }].concat(Object.keys(sideMenuCommonFilterLibContext.dropship).map(item => {
                    return {
                      dropship: sideMenuCommonFilterLibContext.dropship[item],
                      label: item
                    };
              }));
            }
            if (filterIndex == "postalCode" && sideMenuCommonFilterLibContext.postalCode) {
                filterArrayConfigNew[filterIndex]['list'] = [].concat(sideMenuCommonFilterLibContext.postalCode.map(item => {
                    return {postalCode: item};
                }));
            }
            if (filterIndex == "retailer" && filterBtnsSelected?.image_competitor && sideMenuCommonFilterLibContext.retailers && sideMenuCommonFilterLibContext.imageRetailers) {
                let options = boolImgRetailer(filterBtnsSelected?.image_competitor, sideMenuCommonFilterLibContext.retailers, sideMenuCommonFilterLibContext.imageRetailers);
                filterArrayConfigNew[filterIndex]['list'] = options.map(item => {
                    return {retailer: item};
                });
            }
            if (filterIndex == 'price' && sideMenuCommonFilterLibContext.priceTypeLib) {
              filterArrayConfigNew[filterIndex]['list'] = Object.keys(sideMenuCommonFilterLibContext.priceTypeLib).map(priceTypeKey => {
                return {
                  "label": sideMenuCommonFilterLibContext.priceTypeLib[priceTypeKey],
                  "price": priceTypeKey
                }
              });
            }
            if (filterIndex == 'tenant_segment' && sideMenuCommonFilterLibContext.tenantSegmentLib) {
              filterArrayConfigNew[filterIndex]['list'] = Object.keys(sideMenuCommonFilterLibContext.tenantSegmentLib).map(segmentKey => {
                return {
                  "label": sideMenuCommonFilterLibContext.tenantSegmentLib[segmentKey],
                  "tenant_segment": sideMenuCommonFilterLibContext.tenantSegmentLib[segmentKey]
                }
              });
            }
          });
        setFilterArrayConfig(filterArrayConfigNew);
    }, [sideMenuCommonFilterLibContext, filterBtnsSelected]);

    useEffect(() => {
      if (filterBtnsSelected.user && filterBtnsSelected.user.size && firstTimeLoaded == false) {
        applyFilter();
      }
      if (filterBtnsSelected.savedFilter && filterBtnsSelected.savedFilter.length && firstTimeLoaded == false) {
        applyFilter();
      } 
      setTimeout(function(){
        setFirstTimeLoaded(true);
      }, 2000);
      if (!filterApplied()) {
        //applyFilter();
      }
    }, [filterBtnsSelected]);

    const filterApplied = (updateFlag) => {
      let filterAppliedVar = false;
      let advancedFilter = getLocalStorageValue('tenantFeatureConfig');
      if (advancedFilter) {
        advancedFilter = JSON.parse(advancedFilter);
        advancedFilter = advancedFilter.advanced_filter_config;
      }
      Object.keys(filterBtnsSelected).map(filterKey => {
        if (filterKey === 'savedFilter') {
        } else if (defaultSideMenuCommonFilterDefaultContext[filterKey] && Array.from(filterBtnsSelected[filterKey]).join('|') !== Array.from(defaultSideMenuCommonFilterDefaultContext[filterKey]).join('|')) {
          filterAppliedVar = true;
        } else if (advancedFilter && advancedFilter[filterKey]) {
          filterAppliedVar = true;
        }
      });
      if (updateFlag && !filterBtnsSelected.savedFilter) {
        filterAppliedVar = false;
      }
      return filterAppliedVar;
    };

    const renderSelectedFilters = () => {
      const allSelectedArray = [];
      let filters = generateFiltersArr();
      filters.map(filter => {
        const ele = filter;
        if (ele.accessor in filterBtnsSelected && filterBtnsSelected[ele.accessor]) {
          let selectedVals = Array.from(filterBtnsSelected[ele.accessor]);
          let defaultVals = contextApiDefaultValueOnClear[ele.accessor] ? Array.from(contextApiDefaultValueOnClear[ele.accessor]) : [];
          if (selectedVals.length || ('singleSelection' in ele && ele.singleSelection == false)) {

            if (ele.type == "user") {
              let selectedUserFilter = UserPersonalizedFilterValue.allSavedFilter.find(allSavedFilterSingle => {
                return allSavedFilterSingle.id == selectedVals[0];
              });
              if (selectedUserFilter) {
                selectedVals = [selectedUserFilter.filterconfigname];
              }
            }
            
            let filterLabel = getCustomLabel(ele?.label);

            let seletecValStr = selectedVals.join(', ');
            let seletecValStrTitle = selectedVals.join(', ');
            if (ele.range || ele?.type == 'minMax') {
              seletecValStr = selectedVals.join(' - ');
              seletecValStrTitle = selectedVals.join(' - ');
            } else if (ele?.list && ele.list.length && ele?.singleSelection) {
              ele.list.map(singleItem => {
                if (singleItem[ele.accessor] == selectedVals[0] && singleItem.label) {
                  seletecValStr = singleItem.label;
                  seletecValStrTitle = singleItem.label;
                }
              });
            } else if (ele?.list && 'singleSelection' in ele && ele.singleSelection == false && seletecValStr.length == 0) {
              seletecValStr = "ALL";
              seletecValStrTitle = "ALL";
            }

            allSelectedArray.push(<>
              <div
                  key={ele.accessor}
                  className="d-flex align-center selection-circle selected-filter-name"
                >
                  <div className="name me-2">{filterLabel}: </div><div className="value" title={seletecValStrTitle} >{seletecValStr}</div>
              </div>
            </>);

          }
        }
      });

      if (allSelectedArray.length == 0) {
        allSelectedArray.push(<>
          <div
            key="no-val"
            className="d-flex align-center selection-circle selected-filter-name"
          >
            <div className="value" title="No Filters Applied!" >No Filters Applied</div>
          </div>
        </>);
      }

      return <div className="d-flex flex-wrap-row filter-custom-drop-layout w-100">{allSelectedArray}</div>;
    };

    const processRenderFilters = (outputFilters) => {
        let filtergrids = 0;
        let filterLength = outputFilters?.length;
        const filterTotal = [[], []];
        if (filterLength > 0) {
          for (let i = 0; i < filterLength; i++) {
            const ele = outputFilters[i];
            if (['review_count', 'rating', 'best_seller', 'third_party', 'image_competitor', 'private_label', 'brand_gap', 'tenant_priority'].includes(ele.name)) {
              filtergrids += 1;
            } else {
              filtergrids += 2;
            }
            ele.label = getCustomLabel(ele?.label);
            if (ele.name == 'review_count') {
              ele.minSuggestions = getReviewCountMinSuggestions();
              ele.maxSuggestions = getReviewCountMaxSuggestions();
            }
            const list = ele?.list;
            const range = ele?.range;
            let targetIndex = 0;
            if (ele?.group && ele?.group == 'advanced') {
              targetIndex = 1;
            }
            if (ele.type && ele.type == "minMax") {
              filterTotal[targetIndex].push(<FilterMinMax
                key={Math.random()}
                ele={outputFilters[i]}
                openFilterLayout={openFilterLayout}
                filterBtnsSelected={filterBtnsSelected}
                filterBySelectedValues={updateDropDownSelection}
              />);
            } else if (Array.isArray(list)) {
              filterTotal[targetIndex].push(<FilterSelect
                key={Math.random()}
                ele={outputFilters[i]}
                openFilterLayout={openFilterLayout}
                filterBtnsSelected={filterBtnsSelected}
                filterBySelectedValues={updateDropDownSelection}
              />);
            } else if (Array.isArray(range) && range.length > 0) {
              filterTotal[targetIndex].push(<FilterRange
                key={Math.random()}
                ele={outputFilters[i]}
                openFilterLayout={openFilterLayout}
                filterBtnsSelected={filterBtnsSelected}
                filterBySelectedValues={updateDropDownSelection}
              />);
            } else if (ele.type && ele.type == "number") {
              filterTotal[targetIndex].push(<FilterNumber
                key={Math.random()}
                ele={outputFilters[i]}
                openFilterLayout={openFilterLayout}
                filterBtnsSelected={filterBtnsSelected}
                filterBySelectedValues={updateDropDownSelection}
              />);
            } else if (ele.type && ele.type == "boolean") {
              filterTotal[targetIndex].push(<FilterBoolean
                key={Math.random()}
                ele={outputFilters[i]}
                openFilterLayout={openFilterLayout}
                filterBtnsSelected={filterBtnsSelected}
                filterBySelectedValues={updateDropDownSelection}
              />);
            } else if (ele.type && ele.type == "user") {
              filterTotal[targetIndex].push(<SavedFilterSelect 
                key={Math.random()}
                ele={outputFilters[i]}
                openFilterLayout={openFilterLayout}
                filterBtnsSelected={filterBtnsSelected}
                filterBySelectedValues={updateDropDownSelection}
              />);
            } else if (ele.type && ele.type == "locationWidget") {
              filterTotal[targetIndex].push(<FilterLocationWidget 
                key={Math.random()}
                ele={outputFilters[i]}
                openFilterLayout={openFilterLayout}
                filterBtnsSelected={filterBtnsSelected}
                setFilterBtnsSelected={setFilterBtnsSelected}
              />);
            }
          }
        }
        return (
          <>
            <div className="filter-custom-drop-layout" onScroll={e => handleScroll(e)}>
              <div className="w-100 d-flex align-center justify-bw flex-wrap-row">
                <div className="filter-names-container d-flex flex-wrap-row w-100">
                  {filterTotal[0]}
                  {calender && renderCalender()}
                </div>
                {!hideSearchBar && (
                  <SearchBar
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    onClickEventHandler={onClickEventHandler}
                    searchEventHandler={searchEventHandler}
                  />
                )}
              </div>
              {filtergrids <= 40 ? "" : <div id="scroll-target" className="scroll-target" title="Scroll Down" >
                <FontAwesomeIcon icon={faAngleDoubleDown} />
              </div>}
            </div>
            {headerLoading ? renderSubmitButtonLoading() : renderSubmitButton()}
            <div className={`filter-custom-drop-layout ${showAdvanceFilter ? "" : "d-none"}`} style={{maxHeight: 'none', marginTop: '-13px'}} >
              <div className="w-100 d-flex align-center justify-bw flex-wrap-row">
                <div className="filter-names-container d-flex flex-wrap-row w-100">
                  {filterTotal[1]}
                </div>
              </div>
              <div className="scroll-target fw-bold">Advanced Search</div>
            </div>
          </>
        );
    };

    const renderSubmitButtonLoading = () => {
      return (<div className="bd-highlight">
        <div className="filter-button-group pt-3 pb-4">
          <Skeleton height="98%" width="100%" />
        </div>
      </div>);
    };

    const handleScroll = (e) => {
      const target = e.target;
      if (document.getElementById('scroll-target')) {
        document.getElementById('scroll-target').style.opacity = 1-(target.scrollTop/40);
      }
      /*if (target.scrollTop == 0) {
        document.getElementById('scroll-target').style.display = "block";
      } else {
        document.getElementById('scroll-target').style.display = "none";
      }*/
    };

    const onClickEventHandler = (value) => {
        return searchEventHandler(value === undefined ? searchValue : value);
    };

    const generateFiltersArr = () => {
        let filters = [];
        filterConfig.map(filterConfigSingle => {
            if (filterArrayConfig[filterConfigSingle] && filterBtnsSelected[filterConfigSingle]) {
                let newFilter = filterArrayConfig[filterConfigSingle];
                newFilter = checkExtraFilterConfig(newFilter);
                filters.push(newFilter);
            }
        });
        
        let advancedFilter = getLocalStorageValue('tenantFeatureConfig');
        if (advancedFilter) {
          advancedFilter = JSON.parse(advancedFilter);
          advancedFilter = advancedFilter.advanced_filter_config;
          if (advancedFilter) {
            Object.keys(advancedFilter).map(advancedFilterIndex => {
              filters.push(advancedFilter[advancedFilterIndex]);
            });
          }
        }
    
        return filters;
    };

    const renderFilters = () => {
        let filters = generateFiltersArr();
        return processRenderFilters(filters);
    };

    const updateDropDownSelection = (value) => {
        const newSet = { ...filterBtnsSelected, ...value };
        let newSideMenuCommonFilterContext  = {...sideMenuCommonFilterContext, ...value};
        let newsideMenuCommonFilterLibContext = {...sideMenuCommonFilterLibContext}
        if (value?.image_competitor) {
          const [new_img_comp] = Array.from(value?.image_competitor);
          if (new_img_comp === 'Yes') {
            newSet.retailer = new Set(Array.from(newSet.retailer).filter((retailer) => {
              return sideMenuCommonFilterLibContext?.imageRetailers?.includes(retailer);
            }));
          } else if (new_img_comp === 'No') {
            const nonImgRetailers = sideMenuCommonFilterLibContext?.retailers?.filter(retailer => !sideMenuCommonFilterLibContext?.imageRetailers?.includes(retailer));
            newSet.retailer = new Set(Array.from(newSet.retailer).filter((retailer) => {
              return nonImgRetailers.includes(retailer);
            }));
          }
        }
        if(value?.tenant_segment) {
          const [segment] = Array.from(value?.tenant_segment);
          if(segment) {
            let compZipcodesForSelectedSegment = sideMenuCommonFilterLibContext.totalZipCode.filter(e => e.segment.toLowerCase() === segment.toLowerCase());
            newsideMenuCommonFilterLibContext.comp_zipcode = compZipcodesForSelectedSegment;
            newSet.zipcodes = [];
            let segmentWiseRetailers = sideMenuCommonFilterLibContext?.sementWiseRetailerLib[segment.toLowerCase()]
            newsideMenuCommonFilterLibContext.retailers = segmentWiseRetailers;
            setSideMenuCommonFilterLibContext(newsideMenuCommonFilterLibContext);
          }
        }
        setFilterBtnsSelected(newSet);
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
        //updateData(newSet);
    };

    const renderCalender = () => {
        return (
          <div className="filter-name date-picker-container">
            <div className="place-holder d-flex justify-bw">Select Date </div>
            <DatePicker
              placeholder="Select Date"
              value={calenderDate}
              options={calenderOptions}
              onChangeEventHandler={(
                dateRange
                // strDateRange
              ) => setCalenderDate(dateRange)}
              className="date-range"
            />
          </div>
        );
    };

    const renderSubmitButton = () => {
      let advancedFilter = getLocalStorageValue('tenantFeatureConfig');
      if (advancedFilter) {
        advancedFilter = JSON.parse(advancedFilter);
        advancedFilter = advancedFilter.advanced_filter_config;
        if (hideAdvanceButton) {
          advancedFilter = {};
        }
      }
        return (<div className="bd-highlight">
          <div className="filter-name btn-submit filter-button-group" style={{zIndex: '4'}}>
            {((advancedFilter && Object.keys(advancedFilter).length) || miniSubmitButtons) ? <>
              {advancedFilter && Object.keys(advancedFilter).length ? <a 
                //className={filterApplied() ? "btn btn-light btn-lg mb-2 w-100" : "btn btn-light btn-lg mb-2 w-100 disabled"}
                className="btn btn-outline-dark btn-lg mb-2 w-100"
                title="Advance" 
                style={{'--bs-btn-bg': '#fff'}} 
                onClick={e => setShowAdvanceFilter(!showAdvanceFilter)}>
                {showAdvanceFilter ? "Hide Advanced" : "Advanced"}
              </a> : ""}
              <a 
                //className={filterApplied() ? "btn btn-light btn-lg mb-2 w-100" : "btn btn-light btn-lg mb-2 w-100 disabled"}
                className="btn btn-outline-dark btn-lg me-1"
                title="Clear Search" 
                style={{'--bs-btn-bg': '#fff'}} 
                onClick={e => clearFilters()}>
                <FontAwesomeIcon icon={faRefresh} />
              </a>
              {SplitApplyFilterDropdown()}
            </> : <>
              <a 
                //className={filterApplied() ? "btn btn-light btn-lg mb-2 w-100" : "btn btn-light btn-lg mb-2 w-100 disabled"}
                className="btn btn-light btn-lg mb-2 w-100"
                title="Clear Search" 
                style={{'--bs-btn-bg': '#fff'}} 
                onClick={e => clearFilters()}>
                Clear all
              </a>
              {SplitApplyFilterDropdown()}
            </>}
          </div>
        </div>);
    };

    const clearFilters = () => {
        let newFitlerSelected = {};
        let newSideMenuCommonFilterContext  = {...sideMenuCommonFilterContext};
        Object.keys(filterBtnsSelected).map(filterKey => {
          if (filterKey != 'tenant_segment') {
          newFitlerSelected[filterKey] = contextApiDefaultValueOnClear[filterKey];
          newSideMenuCommonFilterContext[filterKey] = contextApiDefaultValueOnClear[filterKey];
          } else if (filterKey == 'tenant_segment') {
            newFitlerSelected[filterKey] = newSideMenuCommonFilterContext[filterKey];
            newSideMenuCommonFilterContext[filterKey] = newSideMenuCommonFilterContext[filterKey];
          }
        });
        //setUserPersonalizedFilterValue({});
        setFilterBtnsSelected(newFitlerSelected);
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
        setClearAllEvent((clrearAllEvent+1));
        let newUserPersonalizedFilterValue = {...UserPersonalizedFilterValue};
        newUserPersonalizedFilterValue.label = "";
        newUserPersonalizedFilterValue.value = "";
        newUserPersonalizedFilterValue.isFavorite = false;
        setUserPersonalizedFilterValue(newUserPersonalizedFilterValue);
        //updateData(newFitlerSelected);
    };

    useEffect(() => {
      if (clrearAllEvent != 0) {
        applyFilter();
      }
    }, [clrearAllEvent]);

    function selectApply(){
        setLocalStorageValue("startNo" ,1);
        setLocalStorageValue("pageNo" ,1);
        applyFilter()
        let filter = {}
        let obj = {...filterBtnsSelected}
        if(Array.from(obj.brand_gap || []).length>0)
        filter.brand_gap = Array.from(obj.brand_gap)
        if(Array.from(obj.brand || []).length>0)
        filter.normalised_brand = Array.from(obj.brand)
        if(Array.from(obj.retailer || []).length>0)
        filter.source = Array.from(obj.retailer)
        if(Array.from(obj.category || []).length>0)
        filter.tenant_category = Array.from(obj.category)
        if(Array.from(obj.subsubsubcategory || []).length>0)
        filter.tenant_sub_sub_subcategory = Array.from(obj.subsubsubcategory)
        if(Array.from(obj.subsubcategory || []).length>0)
        filter.tenant_sub_subcategory = Array.from(obj.subsubcategory)
        if(Array.from(obj.subcategory || []).length>0)
        filter.tenant_subcategory = Array.from(obj.subcategory)
        if (filter && typeof filter === 'object' && Object.keys(filter || {}).length) {
          window.gtag("event", "filter", filter);
        }
    }

    const SplitApplyFilterDropdown = () => {
      let advancedFilter = getLocalStorageValue('tenantFeatureConfig');
      if (advancedFilter) {
        advancedFilter = JSON.parse(advancedFilter);
        advancedFilter = advancedFilter.advanced_filter_config;
      }
        if (isThisFeatureAllowed(SAVED_FILTER) && !props.noSaveFilter) {
          if ((advancedFilter && !hideAdvanceButton) || miniSubmitButtons) {
            return (<Dropdown 
              as={ButtonGroup} drop='down' 
              className="w-50"
            >
              <Button 
                size="lg" 
                className="w-50" 
                variant="dark" 
                title="Apply Search" 
                onClick={e => selectApply()} 
                //className={filterApplied() ? "w-100" : "w-100 disabled"}
              >{((advancedFilter && !hideAdvanceButton) || miniSubmitButtons) ? <FontAwesomeIcon icon={faCheck}/> : "Apply"}</Button>
              {showSavedFilters ? <>
                <Dropdown.Toggle 
                  split variant="dark" 
                  id="dropdown-split-basic"
                  //className={filterApplied() ? "" : "disabled"}
                />
                <Dropdown.Menu>
                  <Dropdown.Item 
                    onClick={() => {
                      handleShow();
                      setIsUpdateFilter(false);
                    }} 
                    className={filterApplied() ? "" : "disabled"}
                  >Save & Apply</Dropdown.Item>
                  <Dropdown.Item 
                    onClick={() => {
                      handleShow();
                      setIsUpdateFilter(true);
                    }} 
                    className={filterApplied('update') ? "" : "disabled"}
                  >Update & Apply</Dropdown.Item>
                </Dropdown.Menu>
              </> : <></>}
            </Dropdown>);
          } else {
            return (<Dropdown 
              as={ButtonGroup} drop='down' 
              className="w-100"
            >
              <Button 
                size="lg" 
                className="w-100" 
                variant="dark" 
                title="Apply Search" 
                onClick={e => selectApply()} 
                //className={filterApplied() ? "w-100" : "w-100 disabled"}
              >Apply</Button>
              {showSavedFilters ? <>
                <Dropdown.Toggle 
                  split variant="dark" 
                  id="dropdown-split-basic"
                  //className={filterApplied() ? "" : "disabled"}
                />
                <Dropdown.Menu>
                  <Dropdown.Item 
                    onClick={() => {
                      handleShow();
                      setIsUpdateFilter(false);
                    }} 
                    className={filterApplied() ? "" : "disabled"}
                  >Save & Apply</Dropdown.Item>
                  <Dropdown.Item 
                    onClick={() => {
                      handleShow();
                      setIsUpdateFilter(true);
                    }} 
                    className={filterApplied('update') ? "" : "disabled"}
                  >Update & Apply</Dropdown.Item>
                </Dropdown.Menu>
              </> : <></>}
            </Dropdown>);
          }
        } else {
          return (
            <Button size="lg" variant="dark" title="Apply Search" className={miniSubmitButtons ? 'w-50' : 'w-100'} onClick={e => applyFilter()}>{miniSubmitButtons ? <FontAwesomeIcon icon={faCheck}/> : "Apply"}</Button>
          )
        }
        
    };

    return (<>
        {props.loadEmptyFilter ? "" : <div className="filters">
            <button className="btn btn-outline-dark btn-sm filter-show-hide rounded-circle" style={{marginTop: "9px"}} onClick={e => setFixTopMenuToOpen(prevValue => !prevValue)}  >
              <FontAwesomeIcon className="cursor-pointer" title="Minimize" icon={faFilter} />
            </button>
            <div className={fixTopMenuToOpen ? "filter-display " : "filter-display filter-selected-display"} style={{position: "relative"}}>
                {fixTopMenuToOpen ? renderFilters() : renderSelectedFilters()}
            </div>
            <SavePersonalizedFilter
                show={showSaveFilterModal}
                handleClose={handleClose}
                filters={generateFiltersArr()}
                filtersContext={filterBtnsSelected}
                applyFilter={applyFilter}
                updateData={setFilterBtnsSelected}
                isUpdateFilter={isUpdateFilter} 
                generateFilter={generateFilter} />
            </div>}
    </>);
};

export default Filters1;